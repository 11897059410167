import { useState } from 'react'
import { useHistory } from 'react-router'
import styles from './style.module.scss'
import { Field } from '@Root/components'
import { Magnifier } from '@Root/assets'
import { useDispatch } from 'react-redux'
import { snackbarActions } from '@Store/snackbar'

export const GlobalSearch = () => {
  const [data, setData] = useState({
    search: '',
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const onChangeValue = name => value => {
    setData({ ...data, [name]: value })
  }
  const validation = () => {
    const { search } = data
    if (search.length < 2) {
      dispatch(snackbarActions.setSnackbar({ text: 'Min 2 characters allowed', isError: true }))
    }
  }

  const onEnterSubmit = e => e.key === 'Enter' && onSubmit()

  const onSubmit = e => {
    if (validation()) return
    history.push(`/home/teid-search?q=${data?.search}&contact_type=all`)
  }
  return (
    <div className={styles.wrapper} onKeyDown={onEnterSubmit}>
      <Field
        classNames={[]}
        style={{ width: '100%', minWidth: '150px', paddingRight: '20px' }}
        value={data?.search}
        onChange={onChangeValue('search')}
        isDisabled={false}
        placeholder='Search TEID'
        maxLength={1500}
        field={'textField'}
        styleWrapper={{ 'grid-template-columns': 'none' }}
      />
      <button className={styles.buttonSearch} type='button' onClick={onSubmit}>
        <img src={Magnifier} alt='magnifier icon' aria-label='Search' />
      </button>
    </div>
  )
}

GlobalSearch.propTypes = {}
